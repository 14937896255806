<template>
  <div class="new-enterprise-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="800px"
      :title="dataset.editRowId ? '编辑企业' : '添加企业'"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div class="table-container">
        <TForm
          ref="htmlFrom"
          lableRow
          :autoCheck="true"
          labelPosition="left"
          :model="htmlDatas"
          :formlist="formlist"
          label-width="88px"
          :rowGutter="16"
        >
          <template slot="positionTree" slot-scope="scope">
            <tree-select
              v-model="htmlDatas.positionId"
              :miltiple="false"
              :show-count="true"
              :disabled="!htmlDatas.projectId"
              placeholder="请选择"
              :options="editPositionList"
              noOptionsText="暂无数据"
              @select="positionIdChange"
            />
          </template>
          <template slot="tagsArrs" slot-scope="scope">
            <div style="display: inline-block">
              <TagBind
                :tagValueList="htmlDatas[scope.current.keys] || []"
                tagType
                :notAuto="true"
                :limit="10"
                :maxlength="6"
                addName="添加标签"
              />
            </div>
          </template>
          <template slot="propertyNumSlot" slot-scope="scope">
            <el-input-number
              v-model="intellectualPropertyNum"
              placeholder="请输入知识产权数量"
              :controls="false"
              style="width: 100%"
            ></el-input-number>
          </template>
          <template slot="peopleNumSlot" slot-scope="scope">
            <el-input-number
              v-model="peopleNum"
              placeholder="请输入在园人数"
              :controls="false"
              style="width: 100%"
            ></el-input-number>
          </template>
          <template slot="registCapiSlot" slot-scope="scope">
            <el-input-number
              v-model="registCapi"
              placeholder="请输入注册资金"
              :controls="false"
              style="width: 100%"
            ></el-input-number>
          </template>
          <template slot="termStartSlot" slot-scope="scope">
            <el-date-picker
              v-model="htmlDatas.termStart"
              type="date"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              placeholder="选择注册日期"
            >
            </el-date-picker>
          </template>
          <template slot="businessWeekSlot" slot-scope="scope">
            <el-select
              style="width: calc(50% - 7px)"
              clearable
              v-model="htmlDatas.businessWeekStart"
            >
              <el-option
                v-for="(item, index) in weekList"
                :value="item.value"
                :label="item.label"
                :key="index"
              ></el-option>
            </el-select>
            <span class="split-span"> - </span>
            <el-select
              clearable
              style="width: calc(50% - 7px)"
              v-model="htmlDatas.businessWeekEnd"
            >
              <el-option
                v-for="(item, index) in weekList"
                :value="item.value"
                :label="item.label"
                :key="index"
              ></el-option>
            </el-select>
          </template>
          <template slot="businessTimeSlot" slot-scope="scope">
            <el-time-picker
              style="width: calc(50% - 7px)"
              v-model="htmlDatas.businessTime"
              placeholder="开始时间"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
            <span class="split-span"> - </span>
            <el-time-picker
              style="width: calc(50% - 7px)"
              v-model="htmlDatas.businessTimeEnd"
              placeholder="结束时间"
              format="HH:mm"
              value-format="HH:mm"
            >
            </el-time-picker>
          </template>
          <template slot="logoFileIdSlot" slot-scope="scope">
            <div class="form-image-line" v-loading="uploadLoading">
              <el-upload
                class="avatar-uploader"
                :action="uploadPath"
                :with-credentials="true"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="imageUrl"
                  @click.stop="handlePictureCardPreview(imageUrl)"
                  :src="imageUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <i
                class="el-icon-delete"
                @click="deleteEnterpriseLogo"
                v-show="htmlDatas.logoFileId"
              ></i>
            </div>
          </template>
        </TForm>
      </div>
      <div class="buttons-line">
        <el-button class="searchbtn" @click="dialogCloseHandler">
          取消</el-button
        >
        <el-button type="primary" @click="validateFroms" :disabled="isHttping">
          提交</el-button
        >
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getPosition } from "@/api/business/order/tenant/order";
import { listToTree } from "@/utils/tree";
import TForm from "@/components/YTable/TForm.vue";
import TagBind from "@/components/TagBind";
import TreeSelect from "@riophae/vue-treeselect";
import pinyin from "js-pinyin";
import { getProjectList } from "@/api/business/order/tenant/order";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "new-enterprise-dialog",
  components: {
    TForm,
    TagBind,
    TreeSelect,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      uploadLoading: false,
      imageUrl: null,
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      weekList: [
        { value: "1", label: "周一" },
        { value: "2", label: "周二" },
        { value: "3", label: "周三" },
        { value: "4", label: "周四" },
        { value: "5", label: "周五" },
        { value: "6", label: "周六" },
        { value: "7", label: "周日" },
      ],
      editPositionList: [], //空间位置数据
      htmlDatas: {
        projectId: "",
        enterpriseTag: [], //标签
        otherQialification: null,
        secondaryIndustry: "",
      },
      intellectualPropertyNum: undefined,
      peopleNum: undefined,
      registCapi: undefined,
      isHttping: false,
      formlist: [
        {
          name: "企业名称",
          keys: "enterpriseName",
          value: "",
          type: "input",
          flex: 12,
          isMust: true,
          inputMethod: this.changeEnterpriseName,
          maxlen: 50,
        },
        {
          name: "企业编码",
          keys: "enterpriseCode",
          value: "",
          type: "input",
          flex: 12,
          isMust: true,
          trigger: ["blur", "change"],
        },
        {
          name: "联系人",
          keys: "liaison",
          value: "",
          type: "input",
          flex: 12,
          isMust: true,
        },
        {
          name: "联系电话",
          keys: "phone",
          value: "",
          type: "input",
          flex: 12,
          isMust: true,
        },
        {
          name: "所属项目",
          keys: "projectId",
          value: "",
          type: "select",
          options: [],
          flex: 12,
          isMust: true,
          changMethod: this.changeProject,
        },
        {
          name: "企业地址",
          keys: "positionId",
          value: null,
          type: "slot",
          slotName: "positionTree",
          flex: 12,
          isMust: true,
        },
        {
          name: "企业负责人",
          keys: "enterpriseLeader",
          value: "",
          type: "input",
          flex: 12,
        },
        {
          name: "负责人电话",
          keys: "leaderPhone",
          value: "",
          type: "input",
          flex: 12,
        },
        {
          name: "法人代表",
          keys: "operName",
          value: "",
          type: "input",
          flex: 12,
        },
        {
          name: "统一社会信用代码",
          keys: "creditNo",
          value: "",
          type: "input",
          flex: 12,
        },
        {
          name: "所属行业",
          keys: "industryName",
          value: "",
          type: "input",
          flex: 12,
        },
        {
          name: "注册资金(万元)",
          keys: "registCapi",
          value: "",
          type: "slot",
          slotName: "registCapiSlot",
          flex: 12,
        },
        {
          name: "注册时间",
          keys: "termStart",
          value: "",
          type: "slot",
          slotName: "termStartSlot",
          flex: 12,
        },
        {
          name: "一级产业",
          keys: "primaryIndustry",
          value: "",
          options: [
            { name: "新一代信息技术", code: "新一代信息技术" },
            { name: "数字与时尚", code: "数字与时尚" },
            { name: "高端装备", code: "高端装备" },
            { name: "绿色低碳", code: "绿色低碳" },
            { name: "新材料", code: "新材料" },
            { name: "生物医药与健康", code: "生物医药与健康" },
            { name: "海洋经济", code: "海洋经济" },
            { name: "合成生物", code: "合成生物" },
            { name: "光载信息", code: "光载信息" },
            { name: "智能机器人", code: "智能机器人" },
            { name: "细胞与基因", code: "细胞与基因" },
            { name: "脑科学与脑机工程", code: "脑科学与脑机工程" },
            { name: "深地深海", code: "深地深海" },
            { name: "量子信息", code: "量子信息" },
            { name: "前沿新材料", code: "前沿新材料" },
          ],
          changMethod: this.primaryChange,
          type: "select",
          flex: 12,
        },
        {
          name: "二级产业",
          keys: "secondaryIndustry",
          value: "",
          options: [],
          type: "select",
          flex: 12,
        },
        {
          name: "主营业务/产品",
          keys: "mainBusiness",
          value: "",
          type: "input",
          flex: 12,
        },
        {
          name: "企业资质",
          keys: "enterpriseQualification",
          value: "",
          options: [
            { name: "科技型中小企业", code: "科技型中小企业" },
            { name: "创新型中小企业", code: "创新型中小企业" },
            { name: "国高", code: "国高" },
            { name: "专精特新", code: "专精特新" },
            { name: "专精特新小巨人", code: "专精特新小巨人" },
            { name: "独角兽企业", code: "独角兽企业" },
            { name: "其他", code: "其他" },
          ],
          type: "select",
          changMethod: this.qualificationChange,
          flex: 12,
        },
        {
          name: "其他",
          keys: "otherQialification",
          value: "",
          type: "input",
          flex: 12,
          isHide: true,
        },
        {
          name: "规上情况",
          keys: "regulatorySituation",
          value: "",
          options: [
            { name: "是", code: "是" },
            { name: "否", code: "否" },
          ],
          type: "select",
          flex: 12,
        },
        {
          name: "知识产权数量",
          keys: "intellectualPropertyNum",
          value: "",
          type: "slot",
          slotName: "propertyNumSlot",
          flex: 12,
        },
        {
          name: "是否上市公司",
          keys: "isListed",
          value: "",
          options: [
            { name: "是", code: "是" },
            { name: "否", code: "否" },
          ],
          type: "select",
          flex: 12,
        },
        {
          name: "在园人数",
          keys: "peopleNum",
          value: "",
          type: "slot",
          slotName: "peopleNumSlot",
          flex: 12,
        },
        {
          name: "营业周",
          keys: "businessWeekStart",
          value: "",
          type: "slot",
          slotName: "businessWeekSlot",
          flex: 12,
        },
        {
          name: "营业时间",
          keys: "businessTime",
          value: "",
          type: "slot",
          slotName: "businessTimeSlot",
          flex: 12,
        },
        {
          name: "企业LOGO",
          keys: "logoFileId",
          value: "",
          type: "slot",
          slotName: "logoFileIdSlot",
          flex: 12,
        },
        { name: "备注", keys: "remark", value: "", type: "textarea", flex: 24 },
        {
          name: "标签",
          keys: "enterpriseTag",
          value: [],
          type: "slot",
          slotName: "tagsArrs",
        },
      ],
    };
  },
  watch: {
    dataset: {
      deep: true, // 深度监听
      immediate: true,
      handler(datas) {
        if (datas.editRowId) {
          this.displayDatas(datas.datas);
        }
      },
    },
  },
  created() {
    this.getPositionDatas();
    this.initProjectArrs();
  },
  methods: {
    deleteEnterpriseLogo() {
      this.imageUrl = "";
      this.htmlDatas.logoFileId = null;
    },
    handlePictureCardPreview(imageUrl) {
      this.dialogImageUrl = imageUrl;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(files, file, fileList, index) {
      console.log("indexindexindexindex", index);
      this.htmlDatas.logoFileId = files[0].fileId;
      this.imageUrl =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        files[0].fileId;
      this.uploadLoading = false;
    },
    beforeAvatarUpload(file, index) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      this.uploadLoading = returnFlag;
      return returnFlag;
    },
    displayDatas(rowData) {
      this.htmlDatas = { ...this.htmlDatas, ...rowData };
      this.htmlDatas.enterpriseTag = rowData.enterpriseTag
        ? rowData.enterpriseTag.split(",")
        : [];
      this.peopleNum = this.htmlDatas.peopleNum || undefined;
      this.intellectualPropertyNum =
        this.htmlDatas.intellectualPropertyNum || undefined;
      this.registCapi = this.htmlDatas.registCapi || undefined;
      this.imageUrl = rowData.logoFileId
        ? envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
          rowData.logoFileId
        : null;
      this.checkOtherQualification();
      if (rowData.projectId) this.getPositionDatas(rowData.projectId);
      if (rowData.primaryIndustry) {
        this.primaryChange(rowData.primaryIndustry, true);
        this.htmlDatas.secondaryIndustry = rowData.secondaryIndustry;
      }
    },
    checkOtherQualification() {
      if (!this.htmlDatas.enterpriseQualification) return;
      const currentOptions = this.formlist.filter(
        (item) => item.keys === "enterpriseQualification"
      )[0].options;
      let exstLength = currentOptions.filter(
        (item) => item.code === this.htmlDatas.enterpriseQualification
      ).length;
      if (
        exstLength === 0 ||
        this.htmlDatas.enterpriseQualification === "其他"
      ) {
        if (this.htmlDatas.enterpriseQualification === "其他") {
          this.htmlDatas.otherQialification = "";
        } else {
          this.htmlDatas.otherQialification =
            this.htmlDatas.enterpriseQualification;
        }
        this.htmlDatas.enterpriseQualification = "其他";
        this.qualificationChange("其他");
      }
    },
    qualificationChange(value) {
      this.formlist.forEach((item) => {
        if (item.keys === "otherQialification") {
          item.isHide = value !== "其他";
        }
      });
    },
    positionIdChange(val) {
      this.$nextTick(() => {
        this.$refs.htmlFrom.validate();
      });
    },
    primaryChange(primary, notSet) {
      const secondMaps = {
        新一代信息技术: [
          "网络与通信",
          "半导体与集成电路",
          "超高清视频显示",
          "智能终端",
          "智能传感器",
        ],
        数字与时尚: ["软件与信息服务", "人工智能", "数字创意", "现代时尚"],
        高端装备: ["高端装备与仪器", "低空经济与空天", "机器人"],
        绿色低碳: ["新能源", "安全节能环保", "智能网联汽车"],
        新材料: ["高性能材料"],
        生物医药与健康: ["生物医药", "高端医疗器械", "大健康"],
        海洋经济: ["海洋"],
      };
      const secondOptions = secondMaps[primary]
        ? secondMaps[primary].map((item) => {
            return {
              name: item,
              code: item,
            };
          })
        : [];
      this.common.insertOptions(
        this.formlist,
        "secondaryIndustry",
        secondOptions
      );
      if (notSet) {
        this.htmlDatas.secondaryIndustry = null;
      }
      if (primary === "其他") {
        this.htmlDatas.secondaryIndustry = "其他";
      }
    },
    initProjectArrs() {
      //加载项目数据
      getProjectList({
        companyId: null,
      }).then((res) => {
        res.map((it) => {
          it.name = it.projectName;
          it.code = it.projectId;
        });
        this.common.insertOptions(this.formlist, "projectId", res); //添加选项数据到所属项目
      });
    },
    changeProject(val) {
      //切换项目
      this.htmlDatas.positionId = null;
      this.getPositionDatas(val);
    },
    // 联动企业名称
    changeEnterpriseName(val) {
      let n = pinyin.getCamelChars(val);
      this.htmlDatas.enterpriseCode = n;
    },
    validateFroms() {
      this.$refs.htmlFrom.validate((isOk) => {
        //进行校验
        if (isOk) {
          this.getFromDatas();
        } else {
          this.$message({ message: "请检查输入是否正确", type: "warning" });
        }
      });
    },
    async getFromDatas() {
      this.isHttping = true;
      setTimeout(() => {
        this.isHttping = false;
      }, 2000);
      let params = {
        ...this.htmlDatas,
      };
      params.peopleNum = this.peopleNum;
      params.intellectualPropertyNum = this.intellectualPropertyNum;
      params.registCapi = this.registCapi;
      // if (params.enterpriseQualification === "其他") {
      //   params.enterpriseQualification = params.otherQialification || "其他";
      // }
      if (params.otherQialification) {
        params.enterpriseQualification = params.otherQialification || "其他";
      }
      console.log("xxx", params);
      let urlType = "insert";
      if (this.dataset.editRowId) {
        urlType = "update"; //编辑
      }
      params.enterpriseTag = params.enterpriseTag.join(",");
      let res = await this.ApiHttp(
        "/organization/enterprise/" + urlType,
        params,
        "post"
      );
      if (res) {
        this.$refs.htmlFrom.resetFields();
        this.$emit("close", true);
      }
    },
    dialogCloseHandler(flag) {
      this.$emit("close", flag);
    },
    getPositionDatas(proId) {
      getPosition(proId).then((res) => {
        let Arr1 = [];
        res.map((it) => {
          if (it.positionId) {
            Arr1.push(it);
          }
        });
        let data = listToTree(Arr1, "positionId", "parentId");
        this.recursivePosition(data);
      });
    },
    recursivePosition(row) {
      let data = row.map((v) => {
        v.label = v.positionName;
        v.id = v.positionId;
        if (v.children != undefined) {
          this.recursivePosition(v.children);
        }
        return v;
      });
      this.editPositionList = data;
    },
  },
};
</script>

<style lang="less" scoped>
.new-enterprise-dialog {
  .table-container {
    max-height: calc(100vh - 250px);
    overflow: hidden auto;
    padding-right: 10px;
  }
  .buttons-line {
    text-align: right;
    margin-top: 10px;
  }
  ::v-deep .el-input-number .el-input__inner {
    text-align: left !important;
  }
  .form-image-line {
    margin-top: 40px;
    display: flex;
    .el-icon-delete {
      color: #ff0000;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .avatar-uploader {
    border: 1px dotted #ccc;
    height: 80px;
    width: 80px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
}
</style>