<template>
  <div class="enterprise-list-new app-container">
    <div class="buttons-line">
      <r-button plain @click="addNewEnterprise">
        <i class="el-icon-plus"></i>
        添加</r-button
      >
      <r-button @click="downHandler">
        <i class="el-icon-upload"></i>
        导入模板</r-button
      >
      <r-button @click="upFiles.isOpen = true">
        <i class="el-icon-upload2"></i>
        导入</r-button
      >
      <r-button @click="exportHandler">
        <i class="el-icon-download"></i>
        导出</r-button
      >
      <r-button type="cancel" @click="initDatas">
        <i class="el-icon-refresh-right"></i>
        刷新</r-button
      >
    </div>
    <div class="table-container">
      <FinalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:productKeySearchSlot>
          <el-cascader
            v-model="dataset.searchLineConfig.projectName.value"
            :options="projectOptions"
            :show-all-levels="false"
            :clearable="true"
            @change="projectChange"
          >
          </el-cascader>
        </template>
      </FinalTable>
    </div>
    <!--导入文件-->
    <el-dialog :visible.sync="upFiles.isOpen" width="40%">
      <template slot="title">
        <title-icon />{{ $t("commons.uploadFile") }}
      </template>
      <div slot="footer">
        <upload
          :ulType="upFiles.uploadType"
          :excelType="upFiles.excelType"
          :rootPath="upFiles.rootPath"
          @success="
            uploadModel = false;
            upFiles.isOpen = false;
          "
          @fail="uploadFail"
        ></upload>
      </div>
    </el-dialog>
    <!-- 新增/编辑企业 -->
    <NewEnterpriseDialog
      v-if="addDialog.show"
      :dataset="addDialog"
      @close="dialogClose"
    />
  </div>
</template>

<script>
import FinalTable from "@/components/FinalTable";
import { downTemplate } from "@/utils/download";
import { envInfo } from "@/constants/envInfo";
import Upload from "@/components/Upload";
import { findCompanyProjectList } from "@/api/business/order/tenant/order";
import NewEnterpriseDialog from "./components/newEnterpriseDialog.vue";

export default {
  name: "enterprise-list-new",
  components: {
    FinalTable,
    Upload,
    NewEnterpriseDialog,
  },
  data() {
    return {
      loadingFlag: false,
      addDialog: {
        show: false,
        editRowId: null,
      },
      projectOptions: [],
      listQuery: {
        current: 1,
        rowCount: 10,
        searchPhrase: null,
        projectId: null,
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          { label: "企业名称", prop: "enterpriseName" },
          { label: "所属项目", prop: "projectName", width: "175" },
          { label: "企业资质", prop: "enterpriseQualification" },
          { label: "规上情况", prop: "regulatorySituation" },
          { label: "一级产业", prop: "primaryIndustry" },
          { label: "二级产业", prop: "secondaryIndustry" },
          { label: "主营业务", prop: "mainBusiness" },
          { label: "联系人", prop: "liaison" },
          { label: "企业地址", prop: "positionName" },
          { label: "操作", prop: "operation", width: "150" },
        ],
        tableData: [],
        searchLineConfig: {
          enterpriseName: {
            type: "input",
            label: "企业名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入企业名称",
            prefixIcon: "el-icon-search",
          },
          projectName: {
            type: "slot",
            slotName: "productKeySearchSlot",
            value: [],
            label: "所属产品",
          },
        },
        // 表格内容配置
        detailConfig: {
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看",
              },
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      upFiles: {
        isOpen: false,
        uploadType: "ExcelImport",
        excelType: "ruge.enterprise",
        rootPath: envInfo.bgApp.organizationPath,
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.initProjectList();
    this.initDatas();
  },
  methods: {
    initProjectList() {
      findCompanyProjectList().then((res) => {
        this.projectOptions = res.map((item) => {
          return {
            value: item.companyId,
            label: item.companyName,
            children: item.projectChildren.map((single) => {
              return {
                value: single.projectId,
                label: single.projectName,
              };
            }),
          };
        });
      });
    },
    projectChange(value) {
      this.listQuery.projectId = value.length ? value[1] : null;
      this.initDatas();
    },
    downHandler() {
      // downTemplate("ruge.enterprise", envInfo.bgApp.organizationPath);

      const fileIdMap = {
        DEV: "39942",
        SIT: "39942",
        UAT: "",
        PROD: "830092",
      };
      // 邹云捷废物搞不定下载，只能先写死文件ID
      const fileUrl = `${
        envInfo.bgApp.archivePath
      }/param/archive/download?dlType=DefaultDownload&fi=${
        fileIdMap[process.env.VUE_APP_ENV]
      }`;
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = "企业列表导入模板";
      a.click();
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
    exportHandler() {
      //导出
      const params = {
        ...this.listQuery,
      };
      this.ApiHttp("/organization/enterprise/export", params, "post").then(
        (taskId) => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          let exportObj = {
            taskId,
            taskName: "企业列表",
            taskStatus: 0,
            rootPath: "organizationPath",
          };
          //将导出任务丢入导出任务列表中
          //异步存储
          this.$store.dispatch("PushExportNotice", exportObj);
        }
      );
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.listQuery.searchPhrase = datas.params.enterpriseName;
        this.initDatas();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.initDatas();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.enterpriseView(datas.row);
            break;
          case "edit":
            this.enterpriseEdit(datas.row);
            break;
          case "delete":
            this.enterpriseDelete(datas.row);
            break;
        }
      }
    },
    enterpriseView({ enterpriseId }) {
      this.$router.push({
        path: "/rwork/enterprise/detail",
        query: {
          enterpriseId,
        },
      });
    },
    enterpriseEdit(row) {
      this.addDialog.show = true;
      this.addDialog.editRowId = row.enterpriseId;
      this.addDialog.datas = row;
    },
    enterpriseDelete({ enterpriseId }) {
      this.$confirm(
        "删除企业，将自动解绑与该企业绑定的设备、人员，但不影响已生成的数据，是否确认删除？",
        "提示"
      )
        .then(() => {
          this.ApiHttp(
            "/organization/enterprise/delete/" + enterpriseId,
            {},
            "delete"
          ).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.initDatas();
          });
        })
        .catch(() => {});
    },
    addNewEnterprise() {
      this.addDialog.show = true;
      this.addDialog.editRowId = null;
      this.addDialog.datas = null;
    },
    dialogClose(flag) {
      this.addDialog.show = false;
      flag === true && this.initDatas();
    },
    async initDatas() {
      this.loadingFlag = true;
      let res = await this.ApiHttp(
        "/organization/enterprise/page",
        this.listQuery
      );
      if (res) {
        this.dataset.tableData = res.rows || [];
        this.dataset.pageVO.total = res.total;
      }
      this.loadingFlag = false;
    },
  },
};
</script>

<style lang="less" scoped>
.enterprise-list-new {
  .buttons-line {
    margin-bottom: 15px;
    i {
      margin-right: 3px;
    }
  }
}
</style>